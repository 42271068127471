import { OktaAuth } from '@okta/okta-auth-js';
import ConfigLoader from './config/Loader';
import Cookies from 'universal-cookie';
declare global {
  interface Window {
    AceIdentity: any;
    AceIdentityEnv: any;
    AceIdentityConfig: any;
  }
}

export enum AceIdentityEnv {
  NonProd = 'non-prod',
  Prod = 'prod',
}

export interface AceIdentityConfig {
  env: string;
  issuer?: string;
  clientId?: string;
  scopes?: string;
  redirectUri: string;
  loginUrl: string;
  bypassAuth2: boolean;
  disableBypassPkce: boolean;
  searchParams: { query: string, value: string }[];
}

class AceIdentity {
  private authClient;
  private scopes;
  private env;
  private config;
  private opt;
  private issuer;
  private clientId;
  private redirectUri;
  private hiddenIframe: any;
  private bypassAuth2;
  private disableBypassPkce;

  constructor(opt: AceIdentityConfig) {
    this.opt = opt;
    this.env = opt.env || AceIdentityEnv.NonProd;
    this.config = ConfigLoader.getConfig(this.env);

    this.scopes = opt?.scopes || this.config.scopes;
    this.issuer = opt?.issuer || this.config.issuer;
    this.clientId = opt?.clientId || this.config.clientId;
    this.redirectUri = opt.redirectUri;
    this.authClient = new OktaAuth({
      'issuer': this.issuer,
      'clientId': this.clientId,
      'redirectUri': `${this.redirectUri}/login/callback`
    });
    this.bypassAuth2 = opt?.bypassAuth2;
    this.disableBypassPkce = opt?.disableBypassPkce;
  }

  oktaAuth(): any {
    return this.authClient;
  }

  async ready(): Promise<any> {
    this.hiddenIframe?.parentElement?.removeChild(this.hiddenIframe);
    this.hiddenIframe = document.createElement('iframe');
    this.hiddenIframe.style.display = 'none';
    this.hiddenIframe.src = this.getLogProxyUrl();
    this.hiddenIframe.loading = 'eager';
    const loaded = new Promise((resolve) => {
      this.hiddenIframe.onload = resolve;
    });
    document.body.appendChild(this.hiddenIframe);
    await loaded;
  }

  async tokens(): Promise<any> {
    try {
      this.sendLog('identity lib - attempt to get tokens');
      const cookies = new Cookies();
      const aceeckp = cookies.get('aceeckp');
      if (!this.disableBypassPkce && aceeckp) {
        this.sendLog('identity lib - bypass pkce');
        const payload = {
          accessToken: aceeckp,
          idToken: ''
        };
        return payload;
      } else {
        this.sendLog('identity lib - use pkce');
        const service = await this.authClient.token.getWithoutPrompt({
          scopes: this.scopes
        });
        const tokens = service.tokens;
        this.sendLog('identity lib - get tokens successfully');
        const payload = {
          accessToken: tokens?.accessToken?.accessToken,
          idToken: tokens?.idToken?.idToken
        };
        return payload;
      }
    } catch (e: any) {
      if (e.message?.toLowerCase().includes('the client specified not to prompt, but the user is not logged in')) {
        this.sendLog('identity lib - failed to get tokens due to not logged in');
        const loginUrlObj = this.opt.loginUrl.split('#');
        if (loginUrlObj.length > 1) {
          this.sendLog('identity lib - redirect to login');

          const redirectUrl = new URL(loginUrlObj[0]);
          redirectUrl.searchParams.append("returnUrl", this.opt.redirectUri);
          redirectUrl.searchParams.append("pkce", "true");
          if (this.bypassAuth2) {
            redirectUrl.searchParams.append("bypassAuth2", "true");
          }
          if (this.opt.searchParams?.length > 0) {
            for (let param of this.opt.searchParams) {
              redirectUrl.searchParams.append(param.query, param.value);
            }
          }
          redirectUrl.hash = loginUrlObj[1];

          setTimeout(() => {
            window.location.assign(redirectUrl);
          }, 1000);
        } else {
          this.sendLog('identity lib - failed to redirect to login');
        }
      } else {
        this.sendLog(`identity lib - failed to get tokens due to ${e.message}`);
      }
      return undefined;
    }
  }

  private getLogProxyUrl(): string | undefined {
    let logproxyUrl;
    const loginUrlObj = this.opt.loginUrl.split('#');
    if (loginUrlObj.length > 1) {
      logproxyUrl = `${loginUrlObj[0]}#logproxy`;
    } else {
      console.log('failed to get logproxy url');
    }
    return logproxyUrl;
  }

  private sendLog(msg: any, data?: { [key: string]: any }) {
    if (this.env !== AceIdentityEnv.Prod) { //don't log to console for prod
      console.log(msg, data);
    }
    const logproxyUrl = this.getLogProxyUrl();
    if (logproxyUrl) {
      const dataNew = {
        msg,
        'issuer': this.issuer,
        'clientId': this.clientId,
        'redirectUri': this.redirectUri,
        ...data
      }
      this.hiddenIframe.contentWindow.postMessage(dataNew, logproxyUrl);
    } else {
      console.log('failed to send logproxy');
    }
  }
}

window.AceIdentity = AceIdentity;
window.AceIdentityEnv = AceIdentityEnv;

export default AceIdentity;