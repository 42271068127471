import configNonProd from './Nonprod';
import configProd from './Prod';

const configs: any = {
  "non-prod": configNonProd,
  "prod": configProd,  
};

class Loader {
  static getConfig = (env: string = "non-prod"): any => {
    if (env) {
      return configs[env];
    }
    throw new Error(`unsupported env: ${env}`);
  };
}

export default Loader;